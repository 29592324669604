import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Journal = () => (
  <Layout class="info">
    <Seo title="About Sean Yalda" />
    <section>
        
    </section>
  </Layout>
)

export default Journal
